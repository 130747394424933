<template>
  <div class="app-content app-container">
    <!-- 搜索 -->
    <div style="margin-bottom: 20px">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        ref="formInline"
        @submit.native.prevent
      >
        <!-- <el-form-item label="企业标签" prop="type">
          <el-select v-model="formInline.type" clearable placeholder="请选择">
            <el-option
              v-for="item in companyType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="信用代码" prop="uscc">
          <el-input
            style="width: 300px"
            v-model="formInline.uscc"
            placeholder="请输入"
            clearable
            @keyup.enter.native="onSubmit"
          ></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input
            style="width: 300px"
            v-model="formInline.name"
            placeholder="请输入"
            clearable
            @keyup.enter.native="onSubmit"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属企业组" prop="groupName">
          <!-- <el-input
                        style="width: 300px"
                        v-model="formInline.groupName"
                        placeholder="请输入"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input> -->
          <el-select
            v-model="formInline.groupName"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in groupList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">搜索</el-button>
          <el-button @click="resetForm('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" plain @click="addReport">新增</el-button>
        <el-button type="danger" plain @click="allDelteClick">删除</el-button>
        <el-button
          type="success"
          :loading="isDownload"
          plain
          @click="exportClick"
          >导出企业填报情况</el-button
        >
        <el-button
          type="success"
          :loading="isExport"
          plain
          @click="exportCompanyClick"
          >导出企业</el-button
        >
        <el-button type="warning" plain @click="sendSmsClick"
          >发送短信</el-button
        >
      </div>
    </div>
    <el-alert
      style="margin-bottom: 10px"
      :closable="false"
      title="包含管理员导入的企业及小程序自主注册进来的企业的汇总表。"
      type="warning"
    >
    </el-alert>
    <!-- 表格 -->
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="#" width="60"></el-table-column>
        <el-table-column prop="uscc" label="信用代码"> </el-table-column>
        <el-table-column prop="name" label="名称"> </el-table-column>
        <el-table-column prop="userName" label="联系人"> </el-table-column>
        <el-table-column prop="phone" label="联系方式"> </el-table-column>
        <el-table-column prop="customsCode" label="海关代码"> </el-table-column>
        <el-table-column prop="provinceName" label="所在地">
          <template slot-scope="scope">
            {{ scope.row.provinceName }},{{ scope.row.cityName }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="type" width="150" label="企业标签">
          <template slot-scope="scope">
            <div>
              <el-tag :type="scope.row.type | companyTypeTag" effect="plain">
                {{ scope.row.type | companyType }}
              </el-tag>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="numberCompany" label="参与问卷数">
        </el-table-column>
       <el-table-column prop="activityNumber" label="企业活跃度">
          <template slot-scope="scope">
            <div>
              {{ scope.row.activityNumber > 2 ? "活跃" : "不活跃" }}
            </div>
          </template>
        </el-table-column>
         <el-table-column prop="groups" label="所属企业组" width="180">
          <template slot-scope="scope">
            <div>
              <el-tag
                style="margin-bottom: 5px"
                v-for="(item, index) in groupFun(scope.row.groupId)"
                :key="index"
                >{{ item }}</el-tag
              >
            </div>
          </template>
        </el-table-column> 
        <el-table-column label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="editClick(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="previewClick(scope.row)"
              >预览</el-button
            >
            <el-button type="text" @click="informationClick(scope.row)"
              >填报情况</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div>
        <Pagination
          v-bind:child-msg="pageparm"
          @callFather="callFather"
        ></Pagination>
      </div>
    </div>
    <!-- 发送短信弹窗 -->
    <el-dialog title="选择发送企业组" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-select v-model="groupId" filterable placeholder="请选择">
          <el-option
            v-for="item in groupList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitSmsClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  getCompanyGroupListApi,
  getCompanyListApi,
  getCompanyDeleteApi,
  sendCompanySmsApi,
} from "@/api/company";
import { mapState } from "vuex";
export default {
  // 注册组件
  components: {
    Pagination,
  },
  data() {
    return {
      dialogVisible: false,
      companyType: this.enterpriseLabel,
      formInline: {
        name: "",
      },
      tableData: [],
      multipleSelection: [],
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 20,
      },
      groupList: [],
      groupId: "", //选择要发送的企业组
    };
  },
  computed: mapState(["isExport", "isDownload"]),

  created() {
    if (sessionStorage.getItem("formInline")) {
      this.formInline = JSON.parse(sessionStorage.getItem("formInline"));
    }
    this.getCompanyGroupListFun();
    this.getCompanyListFun();
  },
  methods: {
    // 企业组名称筛选
    groupFun(list) {
      var str = [];
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
				const names = this.groupList.filter((item) => item.id === element)
				if(names.length){
					var name = names[0].name;
					str.push(name);
				}
      }
      return str;
    },
    // 获取企业组
    async getCompanyGroupListFun() {
      // var start = currentPage ? currentPage * pageSize - pageSize : 0
      var params = {
        start: 0,
        length: 10000,
        draw: 1,
        orderStr: "time desc",
        status:0
      };
      var res = await getCompanyGroupListApi(params);
      this.groupList = res.data;
    },
    async getCompanyListFun(currentPage, pageSize) {
      var start = currentPage ? currentPage * pageSize - pageSize : 0;
      var params = {
        start: start,
        length: 10,
        draw: 1,
        orderStr: "time desc",
      };
      var p = Object.assign(params, this.formInline);
      var res = await getCompanyListApi(p);
      this.tableData = res.data;
      this.pageparm.total = res.recordsTotal;
    },
    // 导出
    exportClick() {
      this.$store.commit("SET_isDownload", true);
      this.download("/doftec/admin-company/download", "企业填报情况列表.xls");
    },
    // 导出企业
    exportCompanyClick() {
      this.$store.commit("SET_isExport", true);
      this.download(
        "/doftec/admin-company/export",
        "企业列表.xls",
        this.formInline
      );
    },
    // 发送短信
    sendSmsClick() {
      this.dialogVisible = true;
    },
    // 提交发送短信
    async submitSmsClick() {
      var params = {
        ids: this.groupId,
      };
      var res = await sendCompanySmsApi(params);
      if (res.code === 200) {
        this.dialogVisible = false;
        this.groupId = "";
        this.$message({
          message: "短信发送成功",
          type: "success",
        });
      }
    },
    // 删除
    async allDelteClick() {
      var ids = this.multipleSelection.map((item) => item.id);
      this.$alert(
        "这确定要删除共" +
          this.multipleSelection.length +
          "条数据吗？删除后将无法恢复",
        "删除提示",
        {
          confirmButtonText: "确定",
          callback: async (action) => {
            if (action === "confirm") {
              var params = {
                ids: ids,
                menuType: "delete",
              };
              var res = await getCompanyDeleteApi(params);
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.getCompanyListFun();
              } else {
                this.$message({
                  type: "error",
                  message: "删除失败：" + res.message,
                });
              }
            }
          },
        }
      );
    },
    onSubmit() {
      sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
      this.getCompanyListFun();
    },
    resetForm(formInline) {
      sessionStorage.removeItem("formInline");
      this.formInline = {
        name: "",
      };
      this.$refs[formInline].resetFields();
      this.getCompanyListFun();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页插件事件
    callFather(parm) {
      this.getCompanyListFun(parm.currentPage, parm.pageSize);
    },
    // 新增模板
    addReport() {
      this.$router.push({
        path: "/company/add",
        query: {
          type: 0,
        },
      });
    },
    // 编辑模板
    editClick(row) {
      // console.log('row', row)
      this.$router.push({
        path: "/company/add",
        query: {
          id: row.uscc,
          type: 0,
        },
      });
    },
    // 预览
    previewClick(row) {
      this.$router.push({
        path: "/company/preview",
        query: {
          type: 0,
          uscc: row.uscc,
        },
      });
    },
    // 填报情况
    informationClick(row) {
      // console.log('row', row);
      this.$router.push({
        path: "/company/information",
        query: {
          type: 0,
          uscc: row.uscc,
        },
      });
    },
  },
};
</script>
<style scoped>
.app-content {
  background-color: #fff;
  padding: 20px;
}
</style>
